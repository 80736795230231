import React from "react";
import { Link } from "react-router-dom";
import Path from "../routes/Path";

const Footer = () => {
  return (
    <>
      <section className="relative">
        <div className="custom-shape-divider-bottom-1724939166">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      {/* footer section start */}
      <footer className="footer-section dot-bg py-5 bg-blue-950 text-white">
        <div className="container">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="mb-6 md:mb-0">
              <div className="bg-white w-fit px-3 rounded-lg mb-2">
                <img
                  src={`${window.location.origin.toString()}/assets/img/mindcoderlogo.webp`}
                  alt="logo"
                  className="img-fluid logo-color"
                  loading="lazy"
                />
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <i className="fa-solid fa-location-dot text-info" />
                  <p className="text-sm">
                    206, Pearl Business park, <br /> In front of Vishnupuri IBus
                    Stop, <br /> Bhanwarkua, Indore, India, 452014
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <i className="fas fa-phone text-info" />
                  <p className="text-sm">+91-7674040233</p>
                </div>
                <div className="flex items-center gap-2">
                  <i className="fas fa-phone text-info" />
                  <p className="text-sm">+91-7389640233</p>
                </div>
                <div className="flex items-center gap-2">
                  <i className="fa-solid fa-envelope text-info" />
                  <p className="text-sm">hr@mindcoders.in</p>
                </div>
              </div>
            </div>
            <div className="mb-6 md:mb-0">
              <h2 className="text-white text-lg font-semibold">
                Primary Pages
              </h2>
              <ul className="list-unstyled footer-nav-list">
                <li>
                  <Link to="/" className="text-decoration-none">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-decoration-none">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/courses" className="text-decoration-none">
                    Courses
                  </Link>
                </li>
                <li>
                  <Link to="/placement" className="text-decoration-none">
                    Placements
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="text-decoration-none">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-decoration-none">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/jobs" className="text-decoration-none">
                    Jobs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mb-6 md:mb-0">
              <h3 className="text-white text-lg font-semibold">Courses</h3>
              <ul className="list-unstyled footer-nav-list">
                <li>
                  <Link
                    to={Path.COURSE_FRONTEND}
                    className="text-decoration-none"
                  >
                    Front-End
                  </Link>
                </li>
                <li>
                  <Link
                    to={Path.COURSE_BACKEND}
                    className="text-decoration-none"
                  >
                    Back-End
                  </Link>
                </li>
                <li>
                  <Link to={Path.COURSE_REACT} className="text-decoration-none">
                    React JS
                  </Link>
                </li>
                <li>
                  <Link to={Path.COURSE_MERN} className="text-decoration-none">
                    Mern Stack
                  </Link>
                </li>
                <li>
                  <Link
                    to={Path.COURSE_DATA_ANALITICS}
                    className="text-decoration-none"
                  >
                    Data Analytics
                  </Link>
                </li>
                <li>
                  <Link
                    to={Path.COURSE_DIGITALMARKETING}
                    className="text-decoration-none"
                  >
                    Digital Marketing
                  </Link>
                </li>
                <li>
                  <Link to={Path.COURSES} className="text-decoration-none">
                    Other Courses{" "}
                    <i className="fa-solid ms-2 fa-person-walking-arrow-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-white text-lg font-semibold">Other Links</h3>
              <ul className="list-unstyled footer-nav-list">
                <li>
                  <Link to={Path.BLOG} className="text-decoration-none">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to={Path.CAREER} className="text-decoration-none">
                    Career
                  </Link>
                </li>
                <li>
                  <Link to={Path.WORKSHOP} className="nav-link">
                    Workshop
                  </Link>
                </li>
                <li>
                  <Link
                    to={Path.PRIVACY_POLICY}
                    className="text-decoration-none"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to={Path.LIFE_AT_MINDCODERS} className="nav-link">
                    Life at Mindcoders
                  </Link>
                </li>
                <li>
                  <Link to={Path.TERMS_CONDITIONS}>Terms &amp; Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* footer section end */}

      {/* footer bottom start */}
      <section className="footer-bottom text-white bg-blue-950 py-4">
        <div className="container">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p className="mb-0">© 2023 Mind Coders Rights Reserved.</p>
            </div>
            <div>
              <ul className="list-unstyled list-inline footer-social-list mb-0 flex justify-center md:justify-end">
                <li className="list-inline-item">
                  <Link
                    to="https://www.facebook.com/profile.php?id=61552791473961"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="https://www.instagram.com/mindcodersofficial/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://twitter.com/Mindcoders1" target="_blank">
                    <i className="fa-brands fa-twitter" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="https://www.linkedin.com/company/mind-coders/?viewAsMember=true"
                    target="_blank"
                  >
                    <i className="fa-brands fa-linkedin" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* footer bottom end */}
    </>
  );
};

export default Footer;
