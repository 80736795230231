import React, { useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useQuery } from '@tanstack/react-query';
import { axiosClient } from "../helpers/axiosInstance";

const fetchJobs = async () => {
  const response = await axiosClient.get("/form/get");
  return response.data;
};

const JobsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { data: jobs = [], isLoading, error } = useQuery({
    queryKey: ['jobs'],
    queryFn: fetchJobs,
  });

  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  const displayJobs = (page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return jobs.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const buttons = [];
    const maxVisiblePages = 0;

    buttons.push(
      <button
        key={1}
        className={`page-btn ${currentPage === 1 ? "active" : ""}`}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    );

    if (currentPage > maxVisiblePages + 1) {
      buttons.push(
        <span key="ellipsis-1" className="ellipsis">
          ...
        </span>
      );
    }

    const startPage = Math.max(2, currentPage - maxVisiblePages);
    const endPage = Math.min(totalPages - 1, currentPage + maxVisiblePages);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`page-btn ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages - maxVisiblePages) {
      buttons.push(
        <span key="ellipsis-2" className="ellipsis">
          ...
        </span>
      );
    }

    buttons.push(
      <button
        key={totalPages}
        className={`page-btn ${currentPage === totalPages ? "active" : ""}`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );

    return buttons;
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <section className="pt-20 d-flex align-items-center position-relative">
        <div className="container">
          <div
            className="row justify-content-between align-items-center"
            id="job-container"
          >
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
              <h1 className="h1 text-color-navy">Latest Jobs</h1>
              <h4 className="text-info h4">Don't miss the opportunities</h4>
            </div>
            {isLoading
              ? Array.from({ length: itemsPerPage }).map((_, index) => (
                  <div
                    key={index}
                    className="col-lg-6 col-md-12 col-sm-12 text-center pb-4"
                  >
                    <div className="job-card">
                      <Skeleton height={30} width={`60%`} />
                      <div>
                        <Skeleton height={20} width={`40%`} />
                      </div>
                    </div>
                  </div>
                ))
              : displayJobs(currentPage).map((job, index) => (
                  <div
                    key={index}
                    className="col-lg-6 col-md-12 col-sm-12 text-center pb-4"
                  >
                    <div className="job-card">
                      <h5 className="h5">{job.title}</h5>
                      <div>
                        <i className="fa-solid fa-hand-point-right text-info"></i>
                        <Link
                          className="text-info"
                          to={job.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click Here
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <div className="pagination flex justify-center items-center space-x-2 py-4">
            <button
              className={`arrow flex items-center gap-3 justify-center pagination-btn rounded-lg px-4 py-2 ${
                currentPage > 1
                  ? "text-white bg-blue-700 hover:scale-105 transition-all"
                  : "text-gray-400 cursor-not-allowed"
              }`}
              onClick={() =>
                handlePageChange(
                  currentPage > 1 ? currentPage - 1 : currentPage
                )
              }
              disabled={currentPage <= 1}
            >
              <span>←</span>{" "}
              <span className="hidden sm:inline nav-text">PREV</span>
            </button>

            <div id="pagination-container" className="flex flex-wrap gap-1">
              {renderPagination()}
            </div>

            <button
              className={`arrow flex items-center gap-3 justify-center pagination-btn rounded-lg px-4 py-2 ${
                currentPage < totalPages
                  ? "text-white bg-blue-700 hover:scale-105 transition-all"
                  : "cursor-not-allowed"
              }`}
              onClick={() =>
                handlePageChange(
                  currentPage < totalPages ? currentPage + 1 : currentPage
                )
              }
              disabled={currentPage >= totalPages}
            >
              <span className="hidden sm:inline nav-text">NEXT</span>{" "}
              <span>→</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobsPage;
