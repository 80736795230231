import { techImages } from "../data/techData.js";
import { cn } from "../lib/utils.ts";
import Marquee from "./magicui/marquee.tsx";

const firstRow = techImages.slice(0, techImages.length / 2);
const secondRow = techImages.slice(techImages.length / 2);

const ReviewCard = ({
  img
}) => {
  return (
    <figure
      className={cn(
        "relative w-64 flex justify-center items-center cursor-pointer overflow-hidden rounded-xl p-4",
        // light styles
        "hover:bg-gray-950/[.05]",
        // dark styles
        "dark:hover:bg-gray-50/[.15]"
      )}
    >
      <img className="w-40" src={img} alt="tech" />
    </figure>
  );
};

export function TechMarquee() {
  return (
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background">
      <Marquee pauseOnHover className="[--duration:20s]">
        {firstRow.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover className="[--duration:20s]">
        {secondRow.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
    </div>
  );
}
