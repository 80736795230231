import React, { useEffect, useState } from "react";
import { BlogContext } from "./BlogContext";
import { axiosClient } from "../../helpers/axiosInstance";

const BlogContextProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  const fetchBlog = async () => {
    try {
      const res = await axiosClient.get("/blog/get");
      // console.log(res.data.data);
      setBlogs(res?.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);
  return <BlogContext.Provider value={{blogs}}>{children}</BlogContext.Provider>;
};

export default BlogContextProvider;
