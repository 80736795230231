import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { BlogContext } from "../../context/blog/BlogContext";

const BlogCard = ({ image }) => {
  const { blogs } = useContext(BlogContext);
  // console.log(blogs);

  const location = useLocation().pathname;
  return (
    <div className="container">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map((item, index) => (
          <div key={index} className="w-full">
            <div className="border-2 border-gray-200 rounded-xl overflow-hidden shadow-md h-full flex flex-col justify-between">
              <div className="blog-card__content p-2 flex flex-col">
                <Link
                  to={`/blog/${item._id}`}
                  className="block text-lg font-semibold mb-2 line-clamp-2"
                >
                  {item.blog_heading}
                </Link>
                {location !== "/" && item.blog_image && (
                  <img
                    className="w-full h-auto aspect-video object-cover mt-2 rounded-lg"
                    src={`${item.blog_image}`}
                    alt={item.blog_heading}
                  />
                )}
                <p className="mt-2 text-base text-gray-700 line-clamp-5">
                  {item.blog_description}
                </p>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center text-sm text-gray-600 mb-3">
                  <div className="flex items-center gap-1">
                    <i className="fa-regular fa-clock"></i>
                    <span>24-July-2024</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <i className="fa-regular fa-keyboard"></i>
                    By Mindcoders
                  </div>
                </div>
                <div>
                  <Link
                    className="bg-slate-900 text-white p-2 rounded-lg hover:bg-slate-800 transition duration-300 w-full block text-center"
                    to={`/blog/${item.name}`}
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogCard;
