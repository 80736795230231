import React, { useEffect } from 'react';

const StopContextMenu = () => {
  useEffect(() => {
    // Prevent the context menu on right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Prevent Ctrl + Shift + I
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        event.preventDefault();
        // console.log('Ctrl + Shift + I is disabled');
      }
    };

    // Attach event listeners
    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return null;
};

export default StopContextMenu;
