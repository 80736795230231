import Path from "../routes/Path";

export const course = [
  {
    id: 1,
    title: "Front-End",
    description: "Perfect for beginners who's seeking a solid foundation in front-end development.",
    image: `/assets/img/courses-logo/frontend-logo.webp`,
    banner: '/assets/img/card/c1.webp',
    url: Path.COURSE_FRONTEND
  },
  {
    id: 2,
    title: "Back-End",
    description: "Ideal for developers transitioning from front-end to full-stack or seeking expertise in server-side logic for dynamic web applications.",
    image: `/assets/img/courses-logo/backend-logo.webp`,
    banner: '/assets/img/card/c6.webp',
    url: Path.COURSE_BACKEND
  },
  {
    id: 3,
    title: "Mern Stack",
    description: "Ideal for developers and engineers eager to master the MERN stack and build high-performance, scalable web applications.",
    image: `/assets/img/courses-logo/MERN-logo.webp`,
    banner: '/assets/img/card/c3.webp',
    url: Path.COURSE_MERN
  },
  {
    id: 4,
    title: "React JS",
    description: "Become a React developer proficient in building responsive and dynamic user interfaces.",
    image: `/assets/img/courses-logo/React-logo.webp`,
    banner: '/assets/img/card/c2.webp',
    url: Path.COURSE_REACT
  },
  {
    id: 5,
    title: "Data Analytics",
    description: "Data analytics course providing comprehensive skills in collecting, processing, and deriving insights from diverse datasets.",
    image: `/assets/img/courses-logo/dataAnalytics-logo.webp`,
    banner: '/assets/img/card/c5.webp',
    url: Path.COURSE_DATA_ANALITICS
  },
  {
    id: 6,
    title: "DSA",
    description: "DSA (Data Structures and Algorithms) course covers a wide array of topics essential for mastering algorithmic problem-solving.",
    image: `/assets/img/courses-logo/dsa-logo.png`,
    banner: '/assets/img/card/4.webp',
    url: Path.COURSE_DSA
  },
  {
    id: 7,
    title: "Digital Marketing",
    description: "Digital marketing course offering strategic expertise in online advertising, SEO, social media.",
    image: `/assets/img/courses-logo/digitalMarketing-logo.webp`,
    banner: '/assets/img/card/c4.webp',
    url: Path.COURSE_DIGITALMARKETING
  },
  {
    id: 8,
    title: "Python Programming",
    description: "Python is an interpreted, object-oriented, high-level programming language with dynamic semantics developed by Guido van Rossum.",
    image: `/assets/img/courses-logo/python-logo.png`,
    banner: '/assets/img/card/1.webp',
    url: Path.COURSE_PYTHON
  },
  {
    id: 9,
    title: "Python Fullstack",
    description: "Python FullStack course covers essential topics for mastering full-stack development with Python and Django.",
    image: `/assets/img/courses-logo/python_fullstack-logo.png`,
    banner: '/assets/img/card/2.webp',
    url: Path.COURSE_PYTHON_FULLSTACK
  },
  {
    id: 10,
    title: "Java Programming",
    description: "Java Programming course provides a comprehensive introduction to Java, covering fundamental to advanced concepts essential for becoming proficient in Java development.",
    image: `/assets/img/courses-logo/java-logo.png`,
    banner: '/assets/img/card/3.webp',
    url: Path.COURSE_JAVA
  },
  {
    id: 11,
    title: "Java Fullstack",
    description: "Java FullStack course covers key topics necessary for mastering full-stack development with Java.",
    image: `/assets/img/courses-logo/java_fullstack-logo.png`,
    banner: '/assets/img/card/8.webp',
    url: Path.COURSE_JAVA_FULLSTACK
  },
  {
    id: 12,
    title: "Data Science",
    description: "Data Science course is a comprehensive program that takes you from a beginner to an advanced level data scientist.",
    image: `/assets/img/courses-logo/datascience-logo.png`,
    banner: '/assets/img/card/5.webp',
    url: Path.COURSE_DATA_SCIENCE
  },
  {
    id: 13,
    title: "Master DSA with C & C++",
    description: "The Data Structures and Algorithms (DSA) course with C/C++ in Indore focuses on mastering core concepts to solve complex computational problems. You’ll learn key data structures like arrays, linked lists, stacks, and trees, and algorithms for sorting, searching, and dynamic programming. The course includes interview preparation to help you succeed in coding interviews, along with assured internships and placement assistance. Through hands-on projects and coding challenges, you'll develop the skills needed for real-world problem-solving and to excel in technical interviews.",
    image: `/assets/img/courses-logo/dsa-logo.png`,
    banner: '/assets/img/card/c7.webp',
    url: Path.COURSE_DSA_MASTER
  },
  {
    id: 14,
    title: "Unlock DSA with C++ Add on Fullstack",
    description: "The Data Structures and Algorithms (DSA) with C/C++ and Add-on Full Stack course in Indore focuses on mastering core concepts to solve complex problems while also building expertise in full-stack development. You’ll learn key data structures like arrays, linked lists, stacks, and trees, along with algorithms for sorting, searching, and dynamic programming, combined with skills in front-end and back-end technologies. The course offers assured internships and placement assistance. Through hands-on projects and coding challenges, you'll develop the skills needed for real-world problem-solving and succeed in both technical and full-stack roles.",
    image: `/assets/img/courses-logo/datascience-logo.png`,
    banner: '/assets/img/card/c8.webp',
    url: Path.COURSE_DSA_ADD_FULLSTACK
  },
];
