import React from "react";
import { baseUrl } from "../helpers/baseUrl";
import ReactTypedComp from "./ReactTypedComp";

const Authorized = () => {
  return (
    <section>
      <hr />
      <div className="w-full justify-around container pb-4 md:py-0 items-center flex flex-row">
        <div className="flex w-1/2 justify-center">
          <ReactTypedComp />
        </div>
        <div className="flex w-1/2 justify-center items-center">
        <img
            className="h-[70px] md:h-[150px] lg:h-[200px]"
            src={`${baseUrl()}/assets/img/msme.png`}
            alt="msme"
          />
          <img
            className="h-[100px] md:h-[150px] lg:h-[300px]"
            src={`${baseUrl()}/assets/img/ncfse.jpg`}
            alt="ncfse"
          />
        </div>
      </div>
    </section>
  );
};

export default Authorized;
