import React from "react";
import Slider from "react-slick";
import { mainPlacement, placementsData } from "../data/placementData";

const PlacementPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // screen width <= 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600, // screen width <= 600px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <section className="testimonial-section py-12">
      <div className="container">
        <div className="text-center mb-12">
          <h4 className="text-color-navy text-2xl md:text-3xl font-bold"><span>Our Shining Star's </span>
            <i className="fa-solid text-[#ffd43b] fa-xs fa-star relative right-5 -rotate-45 bottom-4"></i>
          </h4>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 mb-5">
          {mainPlacement.map((item) => (
            <div data-aos="fade-up" data-aos-duration="1000">
              <img src={item.image} alt="img" />
            </div>
          ))}
        </div>
        <Slider
          {...settings}
          className="flex flex-wrap justify-center text-justify"
        >
          {placementsData.map((data) => (
            <div key={data.id} className="px-2">
              <div className="p-4 bg-slate-100 rounded-lg shadow-md max-w-md mx-auto">
                <div className="flex items-center">
                  <img
                    src={data.image}
                    alt="author"
                    className="rounded-full w-16 h-16 mr-4"
                  />
                  <div>
                    <h6 className="font-semibold">{data.name}</h6>
                    <span className="text-sm">{data.designation}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default PlacementPage;
